import { $win } from '../app/globals';
import { isWindowBelowWidth } from '../utils/window-matches';

const initBlock = ( $header ) => {
	/**
	 * State variables.
	 */
	let scroll = 0;

	/**
	 * Handle scroll.
	 */
	$win.on( 'scroll resize orientationchange', () => {
		const newScroll = $win.scrollTop();

		if ( newScroll > scroll && newScroll > 10 ) {
			if ( newScroll > $header.outerHeight() ) {
				$header.addClass( 'header--slide-up' );
			}
		} else {
			$header.removeClass( 'header--slide-up' );;
		}

		$header.toggleClass( 'is-sticky', newScroll > 50 );
		scroll = newScroll;
	} );

	/**
	 * Handle hamburger click.
	 */
	$( '.js-header-trigger' ).on( 'click', function( event ) {
		event.preventDefault();

		$( this ).toggleClass( 'is-active' );
		$header.toggleClass( 'is-active' );

		$( '.menu-item-has-children' )
			.removeClass( 'is-active' )
			.find( '.sub-menu' )
			.removeClass( 'is-active' );
	} );

	/**
	 * Handle sub-menu trigger click.
	 */
	$( '.menu-item-has-children > a' ).on( 'click', function( event ) {
		event.preventDefault();

		if ( isWindowBelowWidth( 1024 ) ) {
			$( this )
				.parent()
				.toggleClass( 'is-active' )
				.find( '.sub-menu' )
				.toggleClass( 'is-active' );
		}
	} );
};

app.loadBlock( initBlock, 'footer', '.js-header' );
